import * as React from "react";
import { Layout, Menu, Breadcrumb } from "antd";
const { Header, Content, Footer } = Layout;
import "./landing.scss";

const LandingPage = () => {
  return (
    <Layout id="home" className="container layout home">
      <Content className="content">
        <div style={{ padding: "0 10%" }}>
          <div className="heading">
            hobbyist software <br></br>
            developer.
          </div>
          <div className="text" style={{ margin: "25px 0 0 0" }}>
            I'm Devansh Agarwal, software engineer based in India. Passionate about building and shipping beautiful
            products with scalable tech.
          </div>
          <div className="connect" style={{ margin: "65px 0 0 0" }}>
            <div className="connectWithMe"> <a href="#whats-next">connect with me &nbsp;</a> </div>
            <img className="arrowRight" src={require("../images/arrow_right.svg").default}></img>
          </div>
        </div>
      </Content>
      <Footer style={{ padding: "30px 6%" }}>
        <div style={{ display: "flex", color: "#4D4D4D" }}>
          <div className="socialTitles">
            <a href="mailto:devanshagarwal50@gmail.com" style={{ textDecoration: "none", color: "#4d4d4d" }}>Email</a>
          </div>
          <div>/</div>
          <div className="socialTitles"><a target="_blank" href="https://github.com/devanshcodes" style={{ textDecoration: "none", color: "#4d4d4d" }}>GitHub</a></div>
          <div>/</div>
          <div className="socialTitles"><a target="_blank" href="https://www.linkedin.com/in/devansh-agarwal-739702189/" style={{ textDecoration: "none", color: "#4d4d4d" }}>LinkedIn</a></div>
        </div>
      </Footer>
    </Layout>
  );
};

export default LandingPage;
